import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BlockContent } from '../components/BlockContent'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import { imageUrlFor, buildImageObj } from '../helpers/imageUrl.js'
import { Partner } from '../definitions/partner'
import { PhoneEmail } from '../components/molecules/contactInfo/PhoneEmail'
import { SplitImage } from '../components/SplitImage'
import { colors } from '../components/GlobalStyles/vars/colors'
import { fontSizes } from '../components/GlobalStyles/vars/fontSizes'

const PageWrapper = styled.div``

const CertSpan = styled.span`
  color: ${colors.primary};
  font-size: ${fontSizes.md};
`

const Body = styled.div`
  h1 {
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-evenly;
  }

  .phone {
    margin: 15px auto;
    display: flex;
    flex-direction: column;
  }
`

interface Data {
  data: {
    partner: Partner
  }
}

const PartnerPage = ({ data }: Data): JSX.Element => {
  const { partner } = data

  const mainImage =
    partner.mainImage &&
    imageUrlFor(buildImageObj(partner.mainImage))
      .width(800)
      .height(Math.floor((4 / 3) * 800))
      .fit('crop')
      .auto('format')
      .url()

  return (
    <Layout>
      <SEO
        title={partner.seoData.title || 'Partner'}
        location={partner.seoData.location}
        description={partner.seoData._rawDescription}
      />
      <PageWrapper>
        {partner.mainImage && (
          <SplitImage src={mainImage} alt={partner.mainImage.alt}>
            <Body>
              <h1>{partner.name}</h1>

              <ul>
                {partner.certificates.map((cert) => (
                  <li key={cert.id}>
                    <CertSpan>{cert.title}</CertSpan>
                  </li>
                ))}
              </ul>

              <PhoneEmail className="phone" phoneNumber={partner.phone} email={partner.email} />

              <BlockContent body={partner._rawBio} />
            </Body>
          </SplitImage>
        )}
      </PageWrapper>
    </Layout>
  )
}

export default PartnerPage

export const query = graphql`
  query PartnerPageQuery($id: String!) {
    partner: sanityPartner(id: { eq: $id }) {
      _rawBio
      email
      name
      mainImage {
        alt
        ...SanityImage
      }
      phone
      seoData {
        _rawDescription
        location
        title
      }
      certificates {
        id
        title
      }
    }
  }
`
